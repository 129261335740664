import React, { useContext } from "react";
import { Context } from "./../contexts/context";
import Logo from "./logo";
const Footer = () => {
  const {
    data: { copyRights, contact },
  } = useContext(Context);
  return (
    <footer className={contact.contactWays.length > 3 && "position-relative"}>
      <div className="footerTop">
        <div className="container">
          <Logo logoType="EN" />
        </div>
      </div>
      <div className="footerBottom">
        <div className="container">
          <div className="forceTouchesLogo">
            <a rel="noreferrer" target="_blank" href={copyRights.companyUrl}>
              <img src={copyRights.companyLogo} alt="" />
            </a>
          </div>
          <div className="copyRights">
            <span>جميع الحقوق محفوظة ل</span>
            <span className="companyName">
              <a rel="noreferrer" target="_blank" href={copyRights.companyUrl}>
                {copyRights.companyName}
              </a>
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
