import ContextProvider from "./contexts/context";
import Body from "./components/body";

function App() {
  return (
    <div className="App">
      <ContextProvider>
        <Body />
      </ContextProvider>
    </div>
  );
}

export default App;
