import React, { useContext } from "react";
import { Context } from "./../contexts/context";

const Logo = ({ logoType }) => {
  const {
    data: { logo },
  } = useContext(Context);
  return (
    <div className="logo">
      <div
        style={{ backgroundColor: logo.bgColor }}
        className="bg-color d-flex"
      >
        {logoType == "EN" ? (
          <img src={logo.imgEn} alt="LogoEn" />
        ) : (
          <img src={logo.img} alt="logo" />
        )}
      </div>
    </div>
  );
};

export default Logo;
