import React, { useContext } from "react";
import { Context } from "./../contexts/context";
const ContactWays = () => {
  const {
    data: {
      headings: { pageHeading },
      contact,
    },
  } = useContext(Context);
  const classes =
    contact.contactWays.length <= 2 && contact.map.iframeSrc == ""
      ? "col-12 col-md-8 col-sm-12"
      : "col-12 col-sm-6 col-md-4";
  return (
    <div className="contact-ways">
      <div className="row justify-content-md-center">
        {contact.contactWays.map((contactWay, index) => {
          return (
            <div
              key={index}
              style={{ paddingRight: "10px", paddingLeft: "10px" }}
              className={classes}
            >
              <a
                style={{
                  color: contact.textColor,
                  backgroundColor: contact.bgColor,
                }}
                className="btn"
                rel="noreferrer"
                target="_blank"
                href={contactWay.url}
              >
                <span>{contactWay.text}</span>
                <i className={contactWay.iconClass}></i>
              </a>
            </div>
          );
        })}
        {contact.map.iframeSrc && (
          <div
            style={{ paddingRight: "10px", paddingLeft: "10px" }}
            className="col"
          >
            <div
              style={{
                color: contact.textColor,
              }}
              className="map position-relative"
            >
              <iframe
                src={contact.map.iframeSrc}
                title={`خريطة ${pageHeading.txt}`}
                frameBorder="0"
                style={{
                  border: 0,
                  backgroundColor: contact.bgColor,
                }}
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
              ></iframe>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactWays;
