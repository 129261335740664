import logo from "../img/Logo.png";
import logoEn from "../img/LogoEn.png";
import bodyBgImg from "../img/body-bg.jpg";
import forceTouchesLogo from "../img/forceTouchesLogo.png";
const data = {
  bodyBg: {
    img: bodyBgImg,
    overlayColor: "transparebt",
    textColor: "#fff",
  },
  logo: {
    bgColor: "#e63946",
    img: logo,
    imgEn: logoEn,
  },
  headings: {
    pageHeading: { txt: "حلويات مليانه سعاده" },
  },
  address: {
    addressTxt: {
      iconClass: "",
      txtColor: "#fff",
      text: "",
    },
  },
  contact: {
    textColor: "#e63946",
    bgColor: "#fff",
    contactWays: [
      {
        text: "انستقرام",
        url: "https://www.instagram.com/rashnsh",
        iconClass: "fab fa-instagram",
      },
      {
        text: "تويتر",
        url: "https://twitter.com/rashnsh",
        iconClass: "fab fa-twitter",
      },
      {
        text: "واتساب",
        url: "https://api.whatsapp.com/send?phone=966555551032&text=%D8%A7%D9%87%D9%84%D8%A7%20%D9%88%D8%B3%D9%87%D9%84%D8%A7%20%D8%A7%D9%86%D8%A7%20%D8%AC%D8%A7%D9%8A%D9%83%D9%85%20%D9%85%D9%86%20%D9%85%D9%88%D9%82%D8%B9%D9%83%D9%85%20%D8%A7%D9%84%D8%A7%D9%84%D9%83%D8%AA%D8%B1%D9%88%D9%86%D9%8A%20%D9%88%D8%AD%D8%A7%D8%A8%20%D8%A7%D8%B3%D8%A3%D9%84%20%D8%B9%D9%86",
        iconClass: "fab fa-whatsapp",
      },
    ],
    map: {
      iframeSrc: "",
    },
  },
  copyRights: {
    companyLogo: forceTouchesLogo,
    companyUrl: "https://www.forcetouches.com/",
    companyName: "مؤسسة قوة اللمسات",
  },
};
export default data;
