import React, { useContext } from "react";
import { Context } from "./../../contexts/context";
const PageHeading = () => {
  const {
    data: {
      headings: { pageHeading },
    },
  } = useContext(Context);
  return <h1>{pageHeading.txt}</h1>;
};

export default PageHeading;
